import claimFire from './claimFire';
import claimTheft from './claimTheft';
import claimGlass from './claimGlass';
import claimAnimal from './claimAnimal';
import claimWeather from './claimWeather';
import claimVandalism from './claimVandalism';
import claimCommon from './claimCommon';
import claimLiability from './claimLiability';
import claimCollision from './claimCollision';
import vehicleFlows from '../../../models/dataMappings/vehicleFlows';
import {
  getWhatHappened,
  getPageType,
  getCategoryForCollision,
  getAdditionalData, collisionTypes,
} from '../../../helpers/formData/vehicleFormData';
import {
  YES_VALUE, YES_BOOLEAN_VALUE, NO_BOOLEAN_VALUE, claimsKeys,
} from '../../../helpers/formData/formData';
import { claimDataTransform, getConditionalData, PARTY } from '../claimData.helpers';
import { moduleNames } from '../claimData';


const conditionalClaimKeys = [
  {
    conditionTarget: claimsKeys.USE_CALCULATION_APP,
    conditionValue: YES_VALUE,
    resultTarget: claimsKeys.CALCULATIONAPP_EMAIL,
    resultValue: [claimsKeys.thirdParty.EMAIL, claimsKeys.owner.EMAIL],
  },
  {
    conditionTarget: claimsKeys.USE_CALCULATION_APP,
    conditionValue: YES_VALUE,
    resultTarget: claimsKeys.CALCULATIONAPP_PHONE,
    resultValue: [claimsKeys.thirdParty.PHONE, claimsKeys.owner.PHONE],
  },
];

const assignClaimData = (whatHappened, whoFills) => {
  if (whoFills === PARTY) {
    return claimLiability;
  }

  switch (whatHappened) {
    case vehicleFlows.ANIMAL:
      return claimAnimal;
    case vehicleFlows.WEATHER:
      return claimWeather;
    case vehicleFlows.FIRE:
      return claimFire;
    case vehicleFlows.GLASS:
      return claimGlass;
    case vehicleFlows.VANDALISM:
      return claimVandalism;
    case vehicleFlows.THEFT:
      return claimTheft;
    case vehicleFlows.COLLISION:
      return claimCollision;
    default:
      return {};
  }
};

const vehicleTransform = (formData) => {
  let claimData = assignClaimData(formData['what-happened'], formData['who-fills']);
  claimData = {
    ...claimCommon,
    ...claimData,
  };

  // Transformacja danych z claimData
  const data = claimDataTransform(claimData, formData);

  // Przekształcanie warunkowe dla `TRACKER_AGREEMENT`
  if (data[claimsKeys.owner.TRACKER_AGREEMENT] === YES_BOOLEAN_VALUE && (
    data[claimsKeys.SUB_SECTOR_INSURED_PERSON] === collisionTypes.OTHER_CAR_OBJECT_PERSON
      || data[claimsKeys.SUB_SECTOR_INSURED_PERSON] === collisionTypes.BOTH
  )) {
    data[claimsKeys.owner.TRACKER_AGREEMENT] = NO_BOOLEAN_VALUE;
  }

  // Pobierz dodatkowe dane
  const additionalData = getAdditionalData(data);

  // Dodaj `WSPIDWerkstatt` do danych
  const wspidWerkstatt = formData?.['collision_car-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['theft_car-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['animal-damage_animal-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['liability_vehicle-id-non-ergo-by-liability_car-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['vandalism_car-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['damage-caused-by-weather_car-repair_select-workshop']?.WSPIDWerkstatt
  || formData?.['fire-or-explosion_repair-comprehensive_select-workshop']?.WSPIDWerkstatt
  || null;

  return {
    data: {
      ...data,
      ...getConditionalData(data, conditionalClaimKeys),
      ...getWhatHappened(data),
      ...getPageType(data),
      ...getCategoryForCollision(data),
      [claimsKeys.WSPID_WORKSHOP]: wspidWerkstatt, // Dodaj nowe pole do danych
    },
    serviceType: moduleNames.VEHICLE,
    ...additionalData,
  };
};

export default vehicleTransform;
