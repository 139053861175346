import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import injectJss from 'react-jss';
import MailIcon from '@eg/elements/Icons/MailIcon';
import ThumbsUpIcon from '@eg/elements/Icons/ThumbsUpIcon';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import MobilePhoneIcon from '@eg/elements/Icons/MobilePhoneIcon';
import TelephoneIcon from '@eg/elements/Icons/TelephoneIcon';
import Button from '@eg/elements/Button';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../helpers/IconLoader';
import HeadingForm from '../../layout/HeadingForm';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import {
  resetSession, sendClaimData, mapClaimData, moduleNames,
} from '../../services';
import userZoom from '../../services/userZoom';
import { setNavigationState as setNavigationStateAction } from '../../actions/navigation';
import { thankYouProps } from '../../models/panels';
import {
  claimsKeys,
  YES_BOOLEAN_VALUE,
  NO_BOOLEAN_VALUE,
  isUsingCalculationApp,
} from '../../helpers/formData/formData';
import LegalProtection from './LegalProtection';
import styles from './styles.jss';


class ThankYou extends React.PureComponent {
  state = {
    recommendedRepairWorkshop: '',
    kvaService: '',
    trackerAllowed: NO_BOOLEAN_VALUE,
    trackerDisabled: false,
    isLegalProtection: false,
    isProperty: false,
    showRepairRecommendationInfo: false,
    canShowCalculationAppItem: false,
  }

  componentDidMount() {
    const {
      setNavigationState,
      formData,
      pathName,
    } = this.props;
    userZoom(pathName, formData[pathName]);

    // we need to pass a module name, default is Vehicle
    const mappedData = mapClaimData(formData, pathName).data;
    console.log('Mapped Data in ThankYou:', mappedData);

    if (mappedData[claimsKeys.property.EXECUTION_OF_REPAIRS]) {
      this.setState({
        showRepairRecommendationInfo: true,
      });
    }
    if (pathName === moduleNames.VEHICLE) {
      if (formData.vehicle['collision_contact-person'] && formData.vehicle['collision_contact-person'].agreement && (
        mappedData[claimsKeys.owner.TRACKER_AGREEMENT] === NO_BOOLEAN_VALUE
        && formData.vehicle['collision_contact-person'].agreement === true
      )) {
        this.setState({
          trackerDisabled: true,
        });
      }
      this.setState({
        recommendedRepairWorkshop: mappedData[claimsKeys.RECOMMEND_REPAIR_SHOP],
        kvaService: mappedData[claimsKeys.USE_KVA_SERVICE],
        trackerAllowed: mappedData[claimsKeys.owner.TRACKER_AGREEMENT],
      });
      const canShowCalculationAppItem = isUsingCalculationApp(mappedData);
      this.setState({ canShowCalculationAppItem });
    }
    if (pathName === moduleNames.LEGAL_PROTECTION) {
      this.setState({ isLegalProtection: true });
    }
    if (pathName === moduleNames.PROPERTY) {
      this.setState({ isProperty: true });
    }
    setNavigationState(false, false);
    // we need to pass a module name, default is Vehicle
    sendClaimData(formData, pathName);
    console.log('Payload sent to backend:', formData);
    const { store } = this.context;
    resetSession(store);
  }

  checkIfRepairShopChosen = () => {
    const { recommendedRepairWorkshop } = this.state;
    return recommendedRepairWorkshop === 'j';
  }

  checkIfKVAServiceChosen = () => {
    const { kvaService } = this.state;
    return kvaService === 'j';
  }

  checkIfTrackerAllowed = () => {
    const { trackerAllowed } = this.state;
    return trackerAllowed === YES_BOOLEAN_VALUE;
  };

  getTranslation = path => {
    const { localePath } = this.props;
    const { t } = this.context;

    return t([localePath, path].join('.'));
  }

  getItemForList = (icon, name) => {
    const { classes } = this.props;
    const Icon = icon;
    return (
      <li className={classes.item} key={name}>
        <div className={classes.content}>
          <span className={classes.iconContainer}>
            <Icon width={50} height={50} className={classes.icon} />
          </span>
          <span className={classes.text}>
            {this.getTranslation(name)}
          </span>
        </div>
      </li>
    );
  }

  render() {
    const { localePath, classes, options } = this.props;
    const {
      recommendedRepairWorkshop, isLegalProtection, isProperty, canShowCalculationAppItem, kvaService, trackerDisabled,
      showRepairRecommendationInfo,
    } = this.state;
    const { t } = this.context;
    const itemsToDisplay = [
      { icon: MailIcon, name: 'email' },
      { icon: ThumbsUpIcon, name: 'info' },
    ];
    const listClass = recommendedRepairWorkshop || kvaService ? classes.listLong : classes.listShort;

    return (
      <div className={classes.root}>
        <ContentWrapper title={this.getTranslation('button-label')} icon="">
          <HeadingForm
            value={this.getTranslation('heading')}
            commentValue={t([localePath, 'comment'].join('.'))}
          />
          <ul className={listClass}>
            {itemsToDisplay.map(item => this.getItemForList(item.icon, item.name))}
            {this.checkIfTrackerAllowed()
              && this.getItemForList(SvgKontaktdatenDesUnfallbeteiligten, 'tracker-agreement')}
            {trackerDisabled
              && this.getItemForList(SvgKontaktdatenDesUnfallbeteiligten, 'tracker-disabled')}
            {this.checkIfRepairShopChosen()
              && this.getItemForList(InfoIcon, 'repairShop')}
            {this.checkIfKVAServiceChosen()
            && this.getItemForList(InfoIcon, 'kvaService')}
            {(isProperty && showRepairRecommendationInfo) && this.getItemForList(TelephoneIcon, 'phone')}
            {canShowCalculationAppItem && this.getItemForList(MobilePhoneIcon, 'calculation-app')}
          </ul>
          <p className={classes.linkContainer}>
            <a
              href="https://www.ergo.de"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <Button variant="secondary" size="large" className={classes.button}>
                { this.getTranslation('link') }
              </Button>
            </a>
          </p>
          { isLegalProtection && <LegalProtection options={options} getTranslation={this.getTranslation} />}
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({ formData }) => ({ formData });

const mapDispatchToProps = dispatch => ({
  setNavigationState: (isNextActive, isPreviousActive) => {
    dispatch(setNavigationStateAction(isNextActive, isPreviousActive));
  },
});

ThankYou.propTypes = thankYouProps.props;

ThankYou.defaultProps = thankYouProps.defaults;

ThankYou.contextTypes = {
  t: PropTypes.func,
  store: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export { ThankYou as ThankYouPlain };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectJss(styles),
)(ThankYou);
