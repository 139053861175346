import API from '../api';
import decodeHTMLEntities from '../../helpers/decodeHTMLEntities';

export const decodeWorkshops = (data) => data.map((item) => ({
  ...item,
  repairerid: item.repairerid.replace(/^0+/, ''), // Usuwanie zer wiodących
  name: decodeHTMLEntities(item.name), // Dekodowanie encji HTML
}));

const workshopLookup = (postalCode) => API.get(`bff-workshop-provider/workshop?postalCode=${postalCode}`);

export default workshopLookup;
